import React, { useState, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  Box,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";

// Define supported types – note that for some file types the MIME type might not be available,
// so we also check the file extension.
const SUPPORTED_FILE_TYPES = [
  "image/png",
  "image/jpeg",
  ".obj",
  ".fbx",
  ".pdb",
  ".zip",
  ".glb",
  ".glft"
];

const isFileSupported = (file) => {
  // For image files, the MIME type will usually be available.
  if (file.type && (file.type === "image/png" || file.type === "image/jpeg")) {
    return true;
  }
  // For other files, check by extension.
  const extension = file.name.slice(file.name.lastIndexOf(".")).toLowerCase();
  return SUPPORTED_FILE_TYPES.includes(extension);
};

const UploadElementDialog = ({ open, onClose, onUpload }) => {
  const [newElementName, setNewElementName] = useState("");
  const [newElementDescription, setNewElementDescription] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);

  // Handle drag and drop.
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer.files[0];
      try {
        if (!isFileSupported(file)) {
          throw new Error(
            "Unsupported file type. Supported file types: FBX, OBJ, JPG, PNG, PDB, ZIP, GLB, GLTF"
          );
        }
        setSelectedFile(file);
      } catch (error) {
        console.error(error);
        alert(error.message);
      }
      e.dataTransfer.clearData();
    }
  };

  const handleFileInputChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      try {
        if (!isFileSupported(file)) {
          throw new Error(
            "Unsupported file type. Supported file types: FBX, OBJ, JPG, PNG, PDB, GLB, GLTF"
          );
        }
        setSelectedFile(file);
      } catch (error) {
        console.error(error);
        alert(error.message);
      }
    }
  };

  const handleClickDropzone = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleUploadClick = async () => {
    setLoading(true);
    try {
      await onUpload({
        name: newElementName,
        description: newElementDescription,
        file: selectedFile,
      });
      // Optionally reset state after successful upload.
      setNewElementName("");
      setNewElementDescription("");
      setSelectedFile(null);
    } catch (error) {
      console.error("Upload failed:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Upload New Element</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12}>
            <TextField
              label="Element Name"
              value={newElementName}
              onChange={(e) => setNewElementName(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Element Description"
              value={newElementDescription}
              onChange={(e) => setNewElementDescription(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Box
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              onClick={handleClickDropzone}
              sx={{
                border: "2px dashed grey",
                borderRadius: 2,
                p: 2,
                textAlign: "center",
                cursor: "pointer",
              }}
            >
              {selectedFile ? (
                <Typography>{selectedFile.name}</Typography>
              ) : (
                <Typography>
                  Drag and drop a file here, or click to select a file
                </Typography>
              )}
              <input
                type="file"
                accept="image/png, image/jpeg, .obj, .fbx, .pdb, .zip, .glb, .gltf"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={handleFileInputChange}
              />
            </Box>
            <Typography variant="caption">
              Supported file types: FBX, OBJ, JPG, PNG, PDB, ZIP, GLB, GLTF
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleUploadClick}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "Create & Upload"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadElementDialog;
