import React from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";

const unitOptions = [
  { value: 0, label: "fm" },
  { value: 1, label: "pm" },
  { value: 2, label: "A" },
  { value: 3, label: "nm" },
  { value: 4, label: "um" },
  { value: 5, label: "mm" },
  { value: 6, label: "cm" },
  { value: 7, label: "m" },
  { value: 8, label: "km" },
  { value: 9, label: "Mm" },
  { value: 10, label: "Gm" },
  { value: 11, label: "Tm" },
  { value: 12, label: "Pm" },
  { value: 13, label: "Em" },
  { value: 14, label: "parsec" },
  { value: 15, label: "megaParsec" },
  { value: 16, label: "lightYear" },
  { value: 17, label: "None" },
];

const WorldUnitEditor = ({ worldUnit, onChange }) => (
  <Box
    sx={{
      width: "100%",
      display: "flex",
      justifyContent: "center",
      mt: 2,
    }}
  >
    <Box
      sx={{
        width: "100%",
        maxWidth: 600,
        p: 1,
        border: "1px solid #ddd",
        borderRadius: 1,
      }}
    >
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <Typography variant="subtitle1">World Unit:</Typography>
        </Grid>
        <Grid item xs>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                label="Value"
                value={worldUnit.value}
                onChange={(e) => onChange("value", e.target.value)}
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                select
                label="Unit"
                value={worldUnit.units}
                onChange={(e) =>
                  onChange("units", parseInt(e.target.value, 10))
                }
                size="small"
                SelectProps={{ native: true }}
                fullWidth
              >
                {unitOptions.map((o) => (
                  <option key={o.value} value={o.value}>
                    {o.label}
                  </option>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  </Box>
);

export default WorldUnitEditor;
