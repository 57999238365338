import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  Grid,
  TextField,
  Button,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { useContent } from "../context/ContentContext"; // Adjust the import path as needed
import API from "../services/API"; // For individual system fetch/update calls

const SystemsPage = () => {
  const { mySystems, fetchSystems } = useContent();
  const [systems, setSystems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingId, setEditingId] = useState(null);
  const [editedSystems, setEditedSystems] = useState({});

  // States for sorting
  const [sortField, setSortField] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc");

  // States for delete confirmation dialog
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [systemToDelete, setSystemToDelete] = useState(null);

  // Fetch systems on mount using the context function.
  // useEffect(() => {
  //   fetchSystems();
  // }, [fetchSystems]);

  // Update local state when context systems change.
  useEffect(() => {
    if (mySystems) {
      setSystems(mySystems);
      setLoading(false);
    }
  }, [mySystems]);

  // ----- Edit Existing System Handlers -----
  const handleEdit = async (systemId) => {
    try {
      const response = await API.get(`systems/${systemId}`, {
        requiresAuth: true,
      });
      // Assume the full system data is in response.data.data.
      const fullSystem = response.data;
      setEditingId(systemId);
      setEditedSystems((prev) => ({
        ...prev,
        [systemId]: {
          name: fullSystem.name,
          description: fullSystem.description,
          fullSystem: fullSystem,
        },
      }));
    } catch (error) {
      console.error("Error fetching full system data:", error);
    }
  };

  const handleChange = (systemId, field, value) => {
    setEditedSystems((prev) => ({
      ...prev,
      [systemId]: {
        ...prev[systemId],
        [field]: value,
      },
    }));
  };

  const handleCancel = () => {
    setEditingId(null);
  };

  const handleSave = async (systemId) => {
    const currentData = editedSystems[systemId];
    if (!currentData || !currentData.fullSystem) return;
    const updatedSystem = {
      ...currentData.fullSystem,
        name: currentData.name,
        description: currentData.description,
   
    };

    try {
      await API.post("systems/", updatedSystem, { requiresAuth: true });
      setSystems((prev) =>
        prev.map((sys) =>
          sys.id === systemId
            ? {
                ...sys,
                name: updatedSystem.name,
                description: updatedSystem.description,
              }
            : sys
        )
      );
      setEditingId(null);
    } catch (error) {
      console.error("Error saving system:", error);
    }
  };

  // ----- Delete System Handlers -----
  const handleDeleteClick = (system) => {
    setSystemToDelete(system);
    setDeleteConfirmOpen(true);
  };

  const confirmDelete = async () => {
    if (!systemToDelete) return;
    try {
      await API.get(`systems/${systemToDelete.id}/delete`, {
        requiresAuth: true,
      });
      setSystems((prev) => prev.filter((sys) => sys.id !== systemToDelete.id));
    } catch (error) {
      console.error("Error deleting system:", error);
    } finally {
      setDeleteConfirmOpen(false);
      setSystemToDelete(null);
    }
  };

  const cancelDelete = () => {
    setDeleteConfirmOpen(false);
    setSystemToDelete(null);
  };

  // ----- Sorting -----
  const sortedSystems = [...systems].sort((a, b) => {
    const aValue = a[sortField] || "";
    const bValue = b[sortField] || "";
    if (sortField === "name") {
      return sortOrder === "asc"
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    } else if (sortField === "dateModified") {
      return sortOrder === "asc"
        ? new Date(aValue) - new Date(bValue)
        : new Date(bValue) - new Date(aValue);
    }
    return 0;
  });

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h4" gutterBottom>
        Systems
      </Typography>

      {/* Sorting Controls */}
      <Box sx={{ mb: 2, display: "flex", gap: 2 }}>
        <TextField
          select
          label="Sort By"
          value={sortField}
          onChange={(e) => setSortField(e.target.value)}
          SelectProps={{ native: true }}
          size="small"
        >
          <option value="name">Name</option>
          <option value="dateModified">Date Updated</option>
        </TextField>
        <Button
          variant="outlined"
          size="small"
          onClick={() =>
            setSortOrder((prev) => (prev === "asc" ? "desc" : "asc"))
          }
        >
          Order: {sortOrder === "asc" ? "Ascending" : "Descending"}
        </Button>
      </Box>

      <List>
        {sortedSystems.map((system, index) => (
          <ListItem
            key={system.id}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            {editingId === system.id ? (
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={5}>
                  <TextField
                    label="Display Name"
                    value={editedSystems[system.id].name}
                    onChange={(e) =>
                      handleChange(system.id, "name", e.target.value)
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    label="Description"
                    value={editedSystems[system.id].description}
                    onChange={(e) =>
                      handleChange(system.id, "description", e.target.value)
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2} sx={{ display: "flex", gap: 1 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSave(system.id)}
                  >
                    Save
                  </Button>
                  <Button variant="text" onClick={handleCancel}>
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={5}>
                  <Typography variant="subtitle1">
                    {system.name || "No Name"}
                  </Typography>
                  <Typography variant="caption" color="blue">
                    {system.status?.type}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body1">
                    {system.description || "No Description"}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{ display: "flex", flexDirection: "row", gap: 1 }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => handleEdit(system.id)}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleDeleteClick(system)}
                  >
                    Delete
                  </Button>
                </Grid>
              </Grid>
            )}
            {index < sortedSystems.length - 1 && (
              <Divider
                variant="fullWidth"
                sx={{
                  border: 0,
                  borderBottom: "2px solid #ccc !important",
                  my: 2,
                }}
              />
            )}
          </ListItem>
        ))}
      </List>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteConfirmOpen} onClose={cancelDelete}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete{" "}
            {systemToDelete
              ? systemToDelete.name || "this system"
              : "this system"}
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDelete}>Cancel</Button>
          <Button onClick={confirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SystemsPage;
