import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  Grid,
  TextField,
  Button,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import API from "../services/API"; // Adjust path as needed.
import { useContent } from "../context/ContentContext"; // Adjust path as needed.

const EnvironmentsPage = () => {
  const { myEnvironments, fetchEnvironments } = useContent();
  const [environments, setEnvironments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingId, setEditingId] = useState(null);
  const [editedEnvironments, setEditedEnvironments] = useState({});

  // States for sorting (optional)
  const [sortField, setSortField] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc");

  // States for delete confirmation dialog
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [environmentToDelete, setEnvironmentToDelete] = useState(null);

  // Update local state when myEnvironments changes.
  useEffect(() => {
    if (myEnvironments) {
      setEnvironments(myEnvironments);
      setLoading(false);
    }
  }, [myEnvironments]);

  useEffect(() => {
    console.log("EDITED", editedEnvironments)
  }, [editedEnvironments])

  // ----- Edit Existing Environment Handlers -----
  const handleEdit = async (envId) => {
    try {
      const response = await API.get(`environments/${envId}`, {
        requiresAuth: true,
      });
      // Assume full environment object is directly in response.data.
      const fullEnv = response.data;
      console.log("FULL ENV", fullEnv);
      console.log("ENV ID", envId) 

      // Simply use name and description since displayName is never present.
      setEditingId(envId);
      setEditedEnvironments((prev) => ({
        ...prev,
        [envId]: {
          name: fullEnv.environmentName || "",
          description: fullEnv.description || "",
          fullEnvironment: fullEnv,
        },
      }));
    } catch (error) {
      console.error("Error fetching full environment data:", error);
    }
  };

  const handleChange = (envId, field, value) => {
    setEditedEnvironments((prev) => ({
      ...prev,
      [envId]: {
        ...prev[envId],
        [field]: value,
      },
    }));
  };

  const handleCancel = () => {
    setEditingId(null);
  };

  const handleSave = async (envId) => {
    const currentData = editedEnvironments[envId];
    if (!currentData || !currentData.fullEnvironment) return;
    const updatedEnv = {
      ...currentData.fullEnvironment,
      environmentName: currentData.name,
      description: currentData.description,
    };

    try {
      await API.post("environments/", updatedEnv, { requiresAuth: true });
      setEnvironments((prev) =>
        prev.map((env) =>
          env.id === envId
            ? {
                ...env,
                name: updatedEnv.name,
                description: updatedEnv.description,
              }
            : env
        )
      );
      setEditingId(null);
    } catch (error) {
      console.error("Error saving environment:", error);
    }
  };

  // ----- Delete Environment Handlers -----
  const handleDeleteClick = (env) => {
    setEnvironmentToDelete(env);
    setDeleteConfirmOpen(true);
  };

  const confirmDelete = async () => {
    if (!environmentToDelete) return;
    try {
      await API.get(`environments/${environmentToDelete.id}/delete`, {
        requiresAuth: true,
      });
      setEnvironments((prev) =>
        prev.filter((env) => env.id !== environmentToDelete.id)
      );
    } catch (error) {
      console.error("Error deleting environment:", error);
    } finally {
      setDeleteConfirmOpen(false);
      setEnvironmentToDelete(null);
    }
  };

  const cancelDelete = () => {
    setDeleteConfirmOpen(false);
    setEnvironmentToDelete(null);
  };

  // Sort environments before rendering.
  const sortedEnvironments = [...environments].sort((a, b) => {
    const aValue = a[sortField] || "";
    const bValue = b[sortField] || "";
    if (sortField === "name") {
      return sortOrder === "asc"
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    } else if (sortField === "dateModified") {
      return sortOrder === "asc"
        ? new Date(aValue) - new Date(bValue)
        : new Date(bValue) - new Date(aValue);
    }
    return 0;
  });

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h4" gutterBottom>
        Environments
      </Typography>

      {/* Sorting Controls */}
      <Box sx={{ mb: 2, display: "flex", gap: 2 }}>
        <TextField
          select
          label="Sort By"
          value={sortField}
          onChange={(e) => setSortField(e.target.value)}
          SelectProps={{ native: true }}
          size="small"
        >
          <option value="name">Name</option>
          <option value="dateModified">Date Updated</option>
        </TextField>
        <Button
          variant="outlined"
          size="small"
          onClick={() =>
            setSortOrder((prev) => (prev === "asc" ? "desc" : "asc"))
          }
        >
          Order: {sortOrder === "asc" ? "Ascending" : "Descending"}
        </Button>
      </Box>

      <List>
        {sortedEnvironments.map((env, index) => (
          <ListItem
            key={env.id}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            {editingId === env.id ? (
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={5}>
                  <TextField
                    label="Name"
                    value={editedEnvironments[env.id].name}
                    onChange={(e) =>
                      handleChange(env.id, "name", e.target.value)
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    label="Description"
                    value={editedEnvironments[env.id].description}
                    onChange={(e) =>
                      handleChange(env.id, "description", e.target.value)
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2} sx={{ display: "flex", gap: 1 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSave(env.id)}
                  >
                    Save
                  </Button>
                  <Button variant="text" onClick={handleCancel}>
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={5}>
                  <Typography variant="subtitle1">
                    {env.name || "No Name"}
                  </Typography>
                  <Typography variant="caption" color="blue">
                    {env.status?.type}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body1">
                    {env.description || "No Description"}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{ display: "flex", flexDirection: "row", gap: 1 }}
                >
                  <Button variant="outlined" onClick={() => handleEdit(env.id)}>
                    Edit
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleDeleteClick(env)}
                  >
                    Delete
                  </Button>
                </Grid>
              </Grid>
            )}
            {index < sortedEnvironments.length - 1 && (
              <Divider
                variant="fullWidth"
                sx={{
                  border: 0,
                  borderBottom: "2px solid #ccc !important",
                  my: 2,
                }}
              />
            )}
          </ListItem>
        ))}
      </List>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteConfirmOpen} onClose={cancelDelete}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete{" "}
            {environmentToDelete
              ? environmentToDelete.name || "this environment"
              : "this environment"}
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDelete}>Cancel</Button>
          <Button onClick={confirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EnvironmentsPage;
