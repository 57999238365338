import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  Grid,
  TextField,
  Button,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { useContent } from "../context/ContentContext"; // adjust the path as needed
import API from "../services/API"; // still used for individual item fetches/updates
import NewElementUploader from "../components/elementUploads/NewElementUploader";
import WorldUnitEditor from "../components/WorldUnitEditor";

const ElementsPage = () => {
  const { myElements, fetchElements } = useContent();
  const [elements, setElements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingId, setEditingId] = useState(null);
  const [editedElements, setEditedElements] = useState({});

  // Sorting and delete states
  const [sortField, setSortField] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc");
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [elementToDelete, setElementToDelete] = useState(null);

  // Fetch elements on mount using context.
  useEffect(() => {
    fetchElements();
  }, [fetchElements]);

  // Update local state when context elements change.
  useEffect(() => {
    if (myElements) {
      setElements(myElements);
      setLoading(false);
    }
  }, [myElements]);

  // ----- Edit Existing Element Handlers -----
  const handleEdit = async (elementId) => {
    try {
      const response = await API.get(`elements/${elementId}`, {
        requiresAuth: true,
      });
      const fullElement = response.data.data;
      setEditingId(elementId);
      setEditedElements((prev) => ({
        ...prev,
        [elementId]: {
          displayName: fullElement.labelData.displayName,
          description: fullElement.labelData.description,
          worldUnit: fullElement.worldUnit, // include worldUnit field
          fullElement: fullElement,
        },
      }));
    } catch (error) {
      console.error("Error fetching full element data:", error);
    }
  };

  const handleChange = (elementId, field, value) => {
    setEditedElements((prev) => ({
      ...prev,
      [elementId]: {
        ...prev[elementId],
        [field]: value,
      },
    }));
  };

  // Helper to update nested worldUnit values.
  const handleWorldUnitChange = (elementId, field, value) => {
    setEditedElements((prev) => ({
      ...prev,
      [elementId]: {
        ...prev[elementId],
        worldUnit: {
          ...prev[elementId].worldUnit,
          [field]: value,
        },
      },
    }));
  };

  const handleCancel = () => {
    setEditingId(null);
  };

  const handleSave = async (elementId) => {
    const currentData = editedElements[elementId];
    if (!currentData || !currentData.fullElement) return;
    const updatedElement = {
      ...currentData.fullElement,
      labelData: {
        ...currentData.fullElement.labelData,
        displayName: currentData.displayName,
        description: currentData.description,
      },
      worldUnit: currentData.worldUnit, // include updated worldUnit
    };

    try {
      await API.post("elements/", updatedElement, { requiresAuth: true });
      setElements((prev) =>
        prev.map((el) =>
          el.id === elementId
            ? {
                ...el,
                name: updatedElement.labelData.displayName,
                description: updatedElement.labelData.description,
              }
            : el
        )
      );
      setEditingId(null);
    } catch (error) {
      console.error("Error saving element:", error);
    }
  };

  const retriggerBuild = async (element) => {
    console.log("REBUILDING", element);
    let response = await API.post("build-element", element, {
      requiresAuth: true,
    });
    console.log(response);
  };

  // ----- Delete Element Handlers -----
  const handleDeleteClick = (element) => {
    setElementToDelete(element);
    setDeleteConfirmOpen(true);
  };

  const confirmDelete = async () => {
    console.log(elementToDelete)
    if (!elementToDelete) return;
    try {
      let response = await API.get(`elements/${elementToDelete.id}/delete`, {
        requiresAuth: true,
      });
      console.log(response)
      setElements((prev) => prev.filter((el) => el.id !== elementToDelete.id));
    } catch (error) {
      console.error("Error deleting element:", error);
    } finally {
      setDeleteConfirmOpen(false);
      setElementToDelete(null);
    }
  };

  const cancelDelete = () => {
    setDeleteConfirmOpen(false);
    setElementToDelete(null);
  };

  // Callback for when a new element is created via NewElementUploader.
  const handleNewElementCreated = (newElement) => {
    setElements((prev) => [...prev, newElement]);
  };

  // Sorting the elements array before rendering.
  const sortedElements = [...elements].sort((a, b) => {
    const aValue = a[sortField] || "";
    const bValue = b[sortField] || "";
    if (sortField === "name") {
      return sortOrder === "asc"
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    } else if (sortField === "dateModified") {
      return sortOrder === "asc"
        ? new Date(aValue) - new Date(bValue)
        : new Date(bValue) - new Date(aValue);
    }
    return 0;
  });

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box sx={{ p: 2 }}>
      {/* Render the uploader */}
      <Box sx={{ mb: 2, display: "flex", justifyContent: "center" }}>
        <NewElementUploader onNewElementCreated={handleNewElementCreated} />
      </Box>

      <Typography variant="h4" gutterBottom>
        Elements
      </Typography>

      {/* Sorting Controls */}
      <Box sx={{ mb: 2, display: "flex", gap: 2 }}>
        <TextField
          select
          label="Sort By"
          value={sortField}
          onChange={(e) => setSortField(e.target.value)}
          SelectProps={{ native: true }}
          size="small"
        >
          <option value="name">Name</option>
          <option value="dateModified">Date Updated</option>
        </TextField>
        <Button
          variant="outlined"
          size="small"
          onClick={() =>
            setSortOrder((prev) => (prev === "asc" ? "desc" : "asc"))
          }
        >
          Order: {sortOrder === "asc" ? "Ascending" : "Descending"}
        </Button>
      </Box>

      <List>
        {sortedElements.map((element, index) => (
          <ListItem
            key={element.id}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            {editingId === element.id ? (
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={5}>
                  <TextField
                    label="Display Name"
                    value={editedElements[element.id].displayName}
                    onChange={(e) =>
                      handleChange(element.id, "displayName", e.target.value)
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    label="Description"
                    value={editedElements[element.id].description}
                    onChange={(e) =>
                      handleChange(element.id, "description", e.target.value)
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2} sx={{ display: "flex", gap: 1 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSave(element.id)}
                  >
                    Save
                  </Button>
                  <Button variant="text" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button
                    variant="text"
                    onClick={() =>
                      retriggerBuild(editedElements[element.id].fullElement)
                    }
                  >
                    Rebuildx
                  </Button>
                </Grid>
                {/* Render the WorldUnitEditor */}
                <WorldUnitEditor
                  worldUnit={editedElements[element.id].worldUnit}
                  onChange={(field, value) =>
                    handleWorldUnitChange(element.id, field, value)
                  }
                />
              </Grid>
            ) : (
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={5}>
                  <Typography variant="subtitle1">
                    {element.name || "No Name"}
                  </Typography>
                  <Typography variant="caption" color="blue">
                    {element.status?.type}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body1">
                    {element.description || "No Description"}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{ display: "flex", flexDirection: "row", gap: 1 }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => handleEdit(element.id)}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleDeleteClick(element)}
                  >
                    Delete
                  </Button>
                </Grid>
              </Grid>
            )}
            {index < sortedElements.length - 1 && (
              <Divider
                variant="fullWidth"
                sx={{
                  border: 0,
                  borderBottom: "2px solid #ccc !important",
                  my: 2,
                }}
              />
            )}
          </ListItem>
        ))}
      </List>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteConfirmOpen} onClose={cancelDelete}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete{" "}
            {elementToDelete
              ? elementToDelete.name || "this element"
              : "this element"}
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDelete}>Cancel</Button>
          <Button onClick={confirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ElementsPage;
