import { NavLink } from "react-router-dom";
import "./SideNavigation.scss";

import { ReactComponent as Home } from "../images/Icon_home.svg";

import { ReactComponent as Students } from "../images/Icon_users.svg";

import { ReactComponent as User } from "../images/user_icon.svg"

import { ReactComponent as Guide } from "../images/icon_ai_guide.svg";

import { ReactComponent as Experience } from "../images/icon_experience.svg";

import { ReactComponent as Element } from "../images/icon_elements.svg";

import { ReactComponent as Environment } from "../images/icon_environments.svg";

import { ReactComponent as System } from "../images/icon_systems.svg";
import { Chip, Divider, Typography } from "@mui/material";



const SideNavigation = ({ }) => {
  return (
    <div className="side-navigation">
      <div className="top">
        <NavLink to="/" className="nav-link" end activeClassName="active">
          <Home />
          Dashboard
        </NavLink>
        <NavLink to="/sessions" className="nav-link" activeClassName="active">
          <Students />
          Student sessions
        </NavLink>
        <Divider
          // padding="100px"
          color="#ccc"
          variant="fullWidth"
          sx={{
            my: 2, // vertical margin using theme spacing (usually 8px increments)
            // borderBottomWidth: 2, // thicker line; adjust as needed
            // borderColor: "grey.500", // customize color if desired
          }}
        >
          {/* <Chip label="Deploy" size="small" color="white"/> */}
        </Divider>
      
        <NavLink
          to="/experiences"
          className="nav-link"
          activeClassName="active"
        >
          <Experience />
          Experiences
        </NavLink>
        <NavLink to="/guides" className="nav-link" activeClassName="active">
          <Guide />
          Guides <br />
          (BETA)
        </NavLink>

        <Divider
          color="#ccc"
          variant="fullWidth"
          sx={{
            my: 2, // vertical margin using theme spacing (usually 8px increments)
            // borderBottomWidth: 2, // thicker line; adjust as needed
            // borderColor: "grey.500", // customize color if desired
          }}
        >
          {/* <Chip label="Create" size="small" color="#ccc"/> */}
        </Divider>

        <NavLink to="/elements" className="nav-link" activeClassName="active">
          <Element />
          Elements
        </NavLink>
        <NavLink
          to="/environments"
          className="nav-link"
          activeClassName="active"
        >
          <Environment />
          Environments
        </NavLink>
        <NavLink to="/systems" className="nav-link" activeClassName="active">
          <System />
          Systems
        </NavLink>
      </div>

      <div className="bottom">
        <NavLink to="/account" className="nav-link" activeClassName="active">
          <User />
          Account
        </NavLink>
      </div>
    </div>
  );
}

export default SideNavigation;


