// GuideEditor.js
import React, { useEffect, useState } from "react";
import { TextField, Button, Container } from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import InsightEditor from "./InsightEditor";
import ExperiencesAPI from "../services/ExperiencesAPI";
import SystemOptionsDropdown from "./SystemOptionsDropdown";
import { useContent } from "../context/ContentContext";
import { elementOptions, environmentOptions } from "../data/environments";

// Helper to generate a UUID
const generateId = () => crypto.randomUUID();



const flattenGuideData = (guide) => {
  return {
    ...guide,
    insightDatas: guide.insightDatas
      ? guide.insightDatas
          .filter((item) => item !== null)
          .map((item) => item.data)
      : [],
  };
};


const GuideEditor = ({ initialGuide }) => {
  const [guide, setGuide] = useState({
    name: "New Guide",
    id: generateId(),
    insightDatas: [],
    insightDataIds: [],
  });

  const [systemData, setSystemData] = useState(null);
  const { getItem, getThumbnail } = useContent();
  const [environments, setEnvironments] = useState([]);
  const [elements, setElements] = useState([]);

  useEffect(() => {
    if (initialGuide) {
      setGuide(flattenGuideData(initialGuide));
    }

    if(guide.contextId){
      getItem("systems", guide.contextId).then((data) => {
        console.log("system data", data);
        setSystemData(data);
      });
    }
     


  }, [initialGuide]);


  useEffect(() => {
      if(guide.contextId){
      getItem("systems", guide.contextId).then((data) => {
        console.log("system data", data);
         setSystemData(data);
    });
  }
  }, [guide]);


  useEffect(() => {
    if(systemData){
      console.log("Getting envs and elements", systemData)
      console.log("Environment options", environmentOptions(systemData));
      setEnvironments(environmentOptions(systemData));
      setElements(elementOptions(systemData));
    }
  }, [systemData])
  

  // Function to update auto-set triggers (for triggers with type "InsightComplete")
  const updateAutoCompleteTriggers = (insights) => {
    return insights.map((insight, i) => {
      if (insight.insightTriggers && insight.insightTriggers.length > 0) {
        const updatedTriggers = insight.insightTriggers.map((trigger) => {
          if (trigger.insightTriggerType === 2) {
            // If there's a previous insight, set the trigger's id and name from it.
            if (i > 0) {
              return {
                ...trigger,
                triggerId: insights[i - 1].id,
                name: insights[i - 1].name || "Previous Insight",
              };
            } else {
              // If no previous insight exists, clear the trigger fields.
              // return { ...trigger, triggerId: "", name: "" };
            }
          }
          return trigger;
        });
        return { ...insight, insightTriggers: updatedTriggers };
      }
      return insight;
    });
  };

  // Called when an insight changes
  const handleInsightChange = (index, updatedInsight) => {
    console.log(guide)
    const newInsights = [...guide.insightDatas];
    newInsights[index] = updatedInsight;
    // Auto-update triggers for all insights.
    const updatedInsights = updateAutoCompleteTriggers(newInsights);
    setGuide({ ...guide, insightDatas: updatedInsights });
  };

  // Add a new insight (with default values)
  const handleAddInsight = () => {
    const newInsight = {
      id: generateId(),
      name: "",
      text: "",
      answerKey: "",
      insightType: "", // Let the user select (for example, "Instruction", "Information", "Assessment")
      dismissable: false,
      insightTriggers: [],
      startConditions: [],
      endConditions: [],
    };
    const newInsights = [...guide.insightDatas, newInsight];
    // Update auto-set triggers in case the new insight causes a change.
    setGuide({
      ...guide,
      insightDatas: updateAutoCompleteTriggers(newInsights),
    });
  };

  // Delete an insight and recalc auto-set triggers.
  const handleDeleteInsight = (index) => {
    const newInsights = [...guide.insightDatas];
    newInsights.splice(index, 1);
    setGuide({
      ...guide,
      insightDatas: updateAutoCompleteTriggers(newInsights),
    });
  };

  // When the drag-and-drop operation ends, reorder the list and update auto-set triggers.
  const onDragEnd = (result) => {
    if (!result.destination) return;
    const newInsights = Array.from(guide.insightDatas);
    const [removed] = newInsights.splice(result.source.index, 1);
    newInsights.splice(result.destination.index, 0, removed);
    setGuide({
      ...guide,
      insightDatas: updateAutoCompleteTriggers(newInsights),
    });
  };

  // When the guide is submitted, prepare the payload as before.
  const handleSubmit = async () => {
    const guidePayload = { ...guide };
    // Replace the insightDatas array with insightDataIds
    guidePayload.insightDataIds = guide.insightDatas.map(
      (insight) => insight.id
    );
    delete guidePayload.insightDatas;

    try {
      // POST the guide to your endpoint
       try {
         let response = await ExperiencesAPI.post(
           "guides",
           {
             ...guidePayload,
           },
           {
             requiresAuth: true,
           }
         );

         console.log(response)
       } catch (error) {
         console.error("Error creating experience:", error);
       }

      // POST each insight to the /insights endpoint.
      for (const insight of guide.insightDatas) {
        try{
           let response = await ExperiencesAPI.post(
             "insights",
             {
               ...insight,
             },
             {
               requiresAuth: true,
             }
           );

           console.log(response)

        }catch(error)
        {
          console.error("Error creating insight, error");
        }


       
      }
      alert("Submitted successfully!");
    } catch (error) {
      console.error("Submission error:", error);
    }
  };

  return (
    <Container sx={{ marginTop: 4 }}>
      <TextField
        label="Guide Name"
        value={guide.name}
        onChange={(e) => setGuide({ ...guide, name: e.target.value })}
        fullWidth
        sx={{ marginBottom: 2 }}
      />
      <SystemOptionsDropdown
        value={guide.contextId || ""}
        onChange={(newSystemId) =>
          setGuide({ ...guide, contextId: newSystemId })
        }
      />

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="insightList">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {guide.insightDatas.map((insight, index) => (
                <Draggable
                  key={insight.id}
                  draggableId={insight.id}
                  index={index}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={{
                        ...provided.draggableProps.style,
                        marginBottom: 8,
                      }}
                    >
                      <div {...provided.dragHandleProps}>
                        <strong>::</strong>
                      </div>
                      <InsightEditor
                        insight={insight}
                        index={index}
                        onChange={handleInsightChange}
                        onDelete={handleDeleteInsight}
                        allInsights={guide.insightDatas}
                        environments={environments}
                        elements={elements}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <Button
        variant="outlined"
        onClick={handleAddInsight}
        sx={{ marginRight: 2 }}
      >
        Add Insight
      </Button>
      <Button variant="contained" color="primary" onClick={handleSubmit}>
        Submit Guide
      </Button>
    </Container>
  );
};

export default GuideEditor;
