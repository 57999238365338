import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import API from "../services/API";

export const ContentContext = createContext(null);

/**
 * Provider component that holds and manages content items (systems, elements, environments) in state.
 */
export const ContentProvider = ({ children }) => {
  // contentState will store items in an object keyed by "contentType-itemId".
  // e.g., { "systems-123": {...}, "elements-456": {...}, "environments-789": {...}, ... }
  const [contentState, setContentState] = useState({});
  // State for systems, elements, and environments.
  const [mySystems, setMySystems] = useState([]);
  const [myElements, setMyElements] = useState([]);
  const [myEnvironments, setMyEnvironments] = useState([]);

  // Helper to build a unique key from contentType and itemId.
  const makeKey = (contentType, itemId) => `${contentType}-${itemId}`;

  /**
   * Fetch an item from the API if it isn't already in our state.
   */
  const getItem = useCallback(
    async (contentType, itemId) => {
      const key = makeKey(contentType, itemId);
      const cachedItem = contentState[key];

      if (cachedItem) {
        return cachedItem;
      }

      const response = await API.get(`${contentType}/${itemId}`);
      const data = response.data;

      setContentState((prev) => ({
        ...prev,
        [key]: data,
      }));

      return data;
    },
    [contentState]
  );

  /**
   * Helper to form a thumbnail URL based on the item id.
   */
  const getThumbnail = useCallback((id) => {
    return `http://s3-us-west-2.amazonaws.com/10k-assets/thumbnails/${id}.png`;
  }, []);

  /**
   * Fetch all systems with the query param mine=true.
   */
  const fetchSystems = async () => {
    try {
      const response = await API.get("systems?mine=true", {requiresAuth: true});
            console.log("SYSTEMS", response);

      setMySystems(response.data || []);
    } catch (error) {
      console.error("Error fetching systems:", error);
    }
  };

  /**
   * Fetch all elements with query params.
   */
  const fetchElements = async () => {
    try {
      const response = await API.get(
        "elements?mine=true&version=6000&platform=android",
        { requiresAuth: true }
      );
      setMyElements(response.data || []);
    } catch (error) {
      console.error("Error fetching elements:", error);
    }
  };

  /**
   * Fetch all environments with the query param mine=true.
   */
  const fetchEnvironments = async () => {
    try {
      const response = await API.get("environments?mine=true", {
        requiresAuth: true,
      });
      console.log("ENVIRONMETNS", response)
      setMyEnvironments(response.data || []);
    } catch (error) {
      console.error("Error fetching environments:", error);
    }
  };

  // Fetch systems, elements, and environments when the provider mounts.
  useEffect(() => {
    fetchSystems();
    fetchElements();
    fetchEnvironments();
  }, []);

  const contextValue = {
    getItem,
    getThumbnail,
    mySystems,
    fetchSystems,
    myElements,
    fetchElements,
    myEnvironments,
    fetchEnvironments,
  };

  return (
    <ContentContext.Provider value={contextValue}>
      {children}
    </ContentContext.Provider>
  );
};

/**
 * Custom hook for accessing our content context.
 */
export const useContent = () => useContext(ContentContext);
