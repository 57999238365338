import React, { useState } from "react";
import { Button } from "@mui/material";
import API from "../../services/API"; // Adjust as needed.
import baseElementTemplate from "../../data/baseElement.json";
import UploadElementDialog from "./UploadElementDialog";
import { v4 as uuidv4 } from 'uuid'; // Import the uuid function

const NewElementUploader = ({ onNewElementCreated }) => {
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setUploadDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setUploadDialogOpen(false);
  };

  // Helper function to get image dimensions from a File object.
  const getImageDimensions = (file) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
      img.onerror = (err) => reject(err);
      const reader = new FileReader();
      reader.onload = (e) => {
        img.src = e.target.result;
      };
      reader.onerror = (err) => reject(err);
      reader.readAsDataURL(file);
    });
  };

  const handleUpload = async ({ name, description, file }) => {
    // Clone the base element template and update the relevant fields.
    const newElementPayload = { ...baseElementTemplate };
    newElementPayload.id = newElementPayload.dataObjectID = uuidv4();
    newElementPayload.labelData.displayName = name;
    newElementPayload.labelData.description = description;
    newElementPayload.fileName = file.name;

    const fileExtension = file.name.split(".").pop().toLowerCase();

    switch (fileExtension) {
      case "png":
      case "jpg":
      case "jpeg":
        newElementPayload.visualizerData.dataType = 1;
        newElementPayload.visualizerData.visualizationType = 3;
        newElementPayload.visualizerData.defaultMaterialName =
          "DefaultSpriteMaterial";
        newElementPayload.worldUnit.value = 40;

        try {
          const { width, height } = await getImageDimensions(file);
          newElementPayload.visualizerData.xValue = height / width;
        } catch (err) {
          console.error("Failed to get image dimensions:", err);
        }
        break;
      case "obj":
      case "fbx":
      case "glb":
      case "gltf":
        newElementPayload.visualizerData.dataType = 4;
        newElementPayload.visualizerData.visualizationType = 2;

        break;
      case "pdb":
        newElementPayload.visualizerData.dataType = 1;
        newElementPayload.visualizerData.visualizationType = 6;
        break;
      default:
        // Fallback for unknown file types.
        break;
    }

    try {
      const response = await API.post("elements/", newElementPayload, {
        requiresAuth: true,
      });
      // Expecting a presigned URL in the response.
      const { sourceUploadUrl } = response.data.data;
      console.log("SOURCE URL", sourceUploadUrl);
      if (file && sourceUploadUrl) {
        await fetch(sourceUploadUrl, {
          method: "PUT",
          body: file,
          headers: { "Content-Type": file.type },
        });
      }



      const buildResponse = await API.post("build-element", newElementPayload, {
        requiresAuth: true,
      });

      console.log(buildResponse);

      // Call parent's callback, if provided, to update the list.
      if (onNewElementCreated) {
        onNewElementCreated({
          id: newElementPayload.id,
          name: newElementPayload.labelData.displayName,
          description: newElementPayload.labelData.description,
        });
      }
      handleCloseDialog();
    } catch (error) {
      console.error("Error uploading new element:", error);
    }
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleOpenDialog}>
        Upload New Element
      </Button>
      <UploadElementDialog
        open={uploadDialogOpen}
        onClose={handleCloseDialog}
        onUpload={handleUpload}
      />
    </>
  );
};

export default NewElementUploader;
